import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { Observable, filter, first, lastValueFrom } from 'rxjs';
import {
  CLIENTPORTAL,
  COMMUNITY,
  COMPANY,
  DASHBOARD,
  MENTORDATABASE,
  NETWORKING,
  PROFILE,
  RELATIONS,
  REPORTS,
  RESOURCES,
  INNERSMARTGOALSGENERATOR,
} from 'src/app/app-routing.module';
import { FBKSAWAITING, FINALOVERVIEW, FINALRATING, MATCHES, OVERALLRATING, PROGRESSRATING } from 'src/app/reports/reports.module';
import { MenteeRelation } from 'src/app/shared/types/mentee-relations.types';
import { hasValue } from 'src/app/shared/utils';
import { AppState } from 'src/app/state/reducers';

@Component({
  selector: 'fp-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  constructor(private store: Store<AppState>) {}
  bannerType: string;
  currentDate: Date = new Date();
  mrs$: Observable<MenteeRelation[]> = this.store.select(state => state.menteeRelations);
  role$: Observable<any>;
  role;
  user_role = localStorage.getItem('user_role');
  visibleBanner: boolean = false;
  visibleSidebar: boolean;
  items: MenuItem[];
  RELATIONS = RELATIONS + '/';
  INNERSMARTGOALSGENERATOR = INNERSMARTGOALSGENERATOR;

  async ngOnInit() {
    if (this.user_role === 'ME') {
      this.role$ = this.store.select(state => state.mentee);
      const role$ = this.role$.pipe(filter(hasValue), first());
      this.role = await lastValueFrom(role$);
      this.currentDate.setDate(this.currentDate.getDate() - 7);
    } else if (this.user_role === 'MR') {
      this.role$ = this.store.select(state => state.mentor);
      const role$ = this.role$.pipe(filter(hasValue), first());
      this.role = await lastValueFrom(role$);
      this.currentDate.setDate(this.currentDate.getDate() - 7);
      if (this.role?.status === 46) {
        this.visibleBanner = true;
        this.bannerType = 'offboarded';
      } else if (this.role?.status === 19) {
        this.visibleBanner = true;
        this.bannerType = 'notresposive';
      }
    } else if (this.user_role === 'CH') {
      this.role$ = this.store.select(state => state.coach);
      const role$ = this.role$.pipe(filter(hasValue), first());
      this.role = await lastValueFrom(role$);
      this.currentDate.setDate(this.currentDate.getDate() - 7);
      if (this.role?.status === 18) {
        this.visibleBanner = true;
        this.bannerType = 'offboardedCoach';
      }
    } else if (this.user_role === 'CC') {
      this.role$ = this.store.select(state => state.company);
      this.role$.subscribe(company => {
        if (company) {
          this.role = company;
          const today = this.currentDate.toISOString().split('T')[0];
          if (this.role?.onboarding_start && this.role?.due_dates) {
            if (this.role?.due_dates[1] < today) {
              this.visibleBanner = true;
              this.bannerType = 'final_due';
            } else if (this.role?.due_dates[0] < today) {
              this.visibleBanner = true;
              this.bannerType = 'mid_due';
            }
          }
        }
      });
    }

    if (this.role?.user_data?.date_joined > this.currentDate.toISOString() && this.user_role !== 'CC') {
      this.visibleBanner = true;
      if (this.user_role === 'CH') {
        this.bannerType = 'tutorial-coach';
      } else {
        this.bannerType = 'tutorial';
      }
    }

    if (this.user_role === 'CC') {
      this.items = [
        {
          label: 'Dashboard',
          routerLink: COMPANY,
        },
        {
          label: 'Reports',
          items: [
            { label: 'Mentee-mentor matches', routerLink: REPORTS + '/' + MATCHES },
            { label: 'Overall rating', routerLink: REPORTS + '/' + OVERALLRATING },
            { label: 'Mentee rating - Progress', routerLink: REPORTS + '/' + PROGRESSRATING },
            { label: 'Mentee rating - Final', routerLink: REPORTS + '/' + FINALRATING },
            { label: 'Final overview', routerLink: REPORTS + '/' + FINALOVERVIEW },
            { label: 'Feedbacks awaiting', routerLink: REPORTS + '/' + FBKSAWAITING },
          ],
          expanded: false,
        },
        {
          label: 'Client Portal',
          routerLink: CLIENTPORTAL,
        },
      ];
    } else {
      this.mrs$.subscribe(mrs => {
        const mentoring_items =
          mrs
            ?.filter(menteeRelation => menteeRelation.mr_status === 10)
            .map(menteeRelation => ({
              label:
                this.user_role === 'ME'
                  ? `${menteeRelation.supporter.first_name} ${menteeRelation.supporter.last_name[0]}.`
                  : `${menteeRelation.mentee.first_name} ${menteeRelation.mentee.last_name[0]}.`,
              routerLink: [this.RELATIONS + menteeRelation.id],
            })) ?? [];

        const finished_mentoring_items =
          mrs
            ?.filter(menteeRelation => menteeRelation.mr_status !== 10 && menteeRelation.supporter.type === 'mentor')
            .map(menteeRelation => ({
              label:
                this.user_role === 'ME'
                  ? `${menteeRelation.supporter.first_name} ${menteeRelation.supporter.last_name[0]}.`
                  : `${menteeRelation.mentee.first_name} ${menteeRelation.mentee.last_name[0]}.`,
              routerLink: [this.RELATIONS + menteeRelation.id],
            })) ?? [];
        const coaching_items =
          mrs
            ?.filter(menteeRelation => menteeRelation.mr_status === 11)
            .map(menteeRelation => ({
              label:
                this.user_role === 'ME'
                  ? `${menteeRelation.supporter.first_name} ${menteeRelation.supporter.last_name[0]}.`
                  : `${menteeRelation.mentee.first_name} ${menteeRelation.mentee.last_name[0]}.`,
              routerLink: [this.RELATIONS + menteeRelation.id],
            })) ?? [];
        const finished_coaching_items =
          mrs
            ?.filter(menteeRelation => menteeRelation.mr_status !== 11 && menteeRelation.supporter.type === 'coach')
            .map(menteeRelation => ({
              label:
                this.user_role === 'ME'
                  ? `${menteeRelation.supporter.first_name} ${menteeRelation.supporter.last_name[0]}.`
                  : `${menteeRelation.mentee.first_name} ${menteeRelation.mentee.last_name[0]}.`,
              routerLink: [this.RELATIONS + menteeRelation.id],
            })) ?? [];

        const smartGoalsItem = this.user_role === 'ME' ? [{ label: 'SMART goals', routerLink: [INNERSMARTGOALSGENERATOR] }] : [];

        this.items = [
          ...(this.user_role !== 'CH'
            ? [
                {
                  label: 'Dashboard',
                  routerLink: DASHBOARD,
                },
              ]
            : []),

          // extra menu item for mentor self-selection programme
          ...(mentoring_items.length === 0 &&
          finished_mentoring_items.length === 0 &&
          coaching_items.length === 0 &&
          finished_coaching_items.length === 0 &&
          this.user_role === 'ME' &&
          this.role.programme == 5
            ? [{ label: 'Mentoring', routerLink: RELATIONS }]
            : []),

          // menu items for relations
          ...(mentoring_items.length !== 0 ? [{ label: 'Mentoring', items: [...mentoring_items, ...smartGoalsItem], expanded: true }] : []),
          ...(coaching_items.length !== 0 ? [{ label: 'Coaching', items: coaching_items, expanded: true }] : []),

          ...(finished_mentoring_items.length !== 0
            ? [{ label: 'Finished mentoring', items: finished_mentoring_items, expanded: false }]
            : []),

          ...(finished_coaching_items.length !== 0
            ? [{ label: 'Finished coaching', items: finished_coaching_items, expanded: false }]
            : []),

          // other menu items
          {
            label: 'Resources',
            routerLink: RESOURCES,
          },
          ...(this.user_role !== 'CH'
            ? [
                {
                  label: 'Community',
                  routerLink: COMMUNITY,
                },
              ]
            : []),

          ...(this.user_role === 'ME' ? [{ label: 'Networking', routerLink: NETWORKING }] : []),

          ...(this.user_role === 'MR'
            ? [
                {
                  label: 'Mentor database',
                  routerLink: MENTORDATABASE,
                },
                { label: 'My mentor profile', routerLink: PROFILE },
              ]
            : []),
          ...(this.user_role === 'CH' ? [{ label: 'My coach profile', routerLink: PROFILE }] : []),
        ];
      });
    }
  }

  closeBanner() {
    this.visibleBanner = !this.visibleBanner;
  }

  hideSidebar() {
    this.visibleSidebar = false;
  }
}
